@use '@angular/material'as mat;

@import 'src/app/components/card/card.theme';
@import 'src/app/components/mobile-drawer/mobile-drawer.component.theme';
@import 'src/app/components/asset-table/asset-table.theme';
@import 'src/app/components/jurisdiction-table/jurisdiction-table.theme';
@import 'src/app/components/page-title/page-title.theme';
@import 'src/app/components/no-records/no-records.theme';
@import 'src/app/components/summary/summary.theme';
@import 'src/app/components/dialog/dialog.theme';

@mixin overview-container-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  @include card-theme($theme);
  @include mobile-drawer-theme($theme);
  @include asset-table-theme($theme);
  @include jurisdiction-table-theme($theme);
  @include page-title-theme($theme);
  @include no-records-theme($theme);
  @include summary-theme($theme);
  @include dialog-theme($theme);
  

  .overview-container {
    a {
      color: mat.get-color-from-palette($accent);
      text-decoration: underline;
    }

    .mat-tab-nav-panel {
      position: relative;
    }

    .mat-tab-nav-bar {
      margin-bottom: 1.35rem;
      border-bottom-color: mat.get-color-from-palette($primary, 600);

      .mat-tab-link {
        @include mat.typography-level($zonar-default-typography, body-2);
        color: mat.get-color-from-palette($primary, darker);
        text-decoration: none;
        opacity: 1;
      }

      .mat-tab-link:hover {
        background-color: mat.get-color-from-palette($accent, hover);
        color: mat.get-color-from-palette($primary, darker);
        border-bottom-width: 0.125rem;
        border-bottom-color: mat.get-color-from-palette($foreground, base);
        border-bottom-style: solid;
      }

      .mat-tab-label-active {
        color: mat.get-color-from-palette($foreground, base);
        opacity: 1;
      }

      .mat-ink-bar {
        background-color: mat.get-color-from-palette($accent);
      }
    }

    .data-viz-wrapper {
      margin-bottom: 1rem;

      .data-viz-child {
        .mat-card {
          margin: 0;
          // height: 100%;
        }
      }
    }
  }

  .column-item {
    .column-name {
      text-transform: uppercase;
    }
  }

}